import {
    AuditOutlined,
    CloudSyncOutlined,
    DesktopOutlined,
} from '@ant-design/icons';
import React, { ReactNode } from 'react';

export const messageTypes: {
    [key: string]: {
        label: string;
        color: string | undefined;
        tagColor: string | undefined;
    };
} = {
    info: {
        label: 'Info',
        color: undefined,
        tagColor: 'blue',
    },
    warning: {
        label: 'Varoitus',
        color: 'warning',
        tagColor: 'gold',
    },
    error: {
        label: 'Virhe',
        color: 'danger',
        tagColor: 'red',
    },
};

export const systemColors: { [key: string]: { tag: string; color: string } } = {
    procountor: { tag: 'red', color: '#F75759' },
    tripletex: { tag: 'cyan', color: '#003ef5' },
    netvisor: { tag: 'blue', color: '#007ACA' },
    fivaldi: { tag: 'geekblue', color: '#0fab4d' },
    'ai-inside': { tag: 'magenta', color: '#F53B57' },
    fennoa: { tag: 'cyan', color: '#0b6a98' },
    hausvise: { tag: 'gold', color: '#f4da43' },
};

export const languages = [
    {
        value: 'fi',
        label: 'Suomi',
    },
    {
        value: 'en',
        label: 'Englanti',
    },
];

export const roles = [
    { value: 'ORGANIZATION_USER', label: 'Tavallinen käyttäjä', color: '#fff' },
    {
        value: 'ORGANIZATION_ADMIN',
        label: 'Organisaation ylläpitäjä',
        color: 'blue',
    },
    { value: 'FABRICAI_STAFF', label: 'FabricAI henkilöstö', color: 'magenta' },
    { value: 'FABRICAI_BILLING', label: 'Laskutus', color: 'green' },
    { value: 'SYSTEM_ADMIN', label: 'Pääkäyttäjä', color: 'purple' },
];

export interface ServiceTemplates {
    [key: string]: { name: string; icon: ReactNode };
}

export const serviceTemplates: ServiceTemplates = {
    BACKGROUND_PROCESS: {
        name: 'Taustaprosessi',
        icon: <CloudSyncOutlined />,
    },
    CUSTOMER_DOES_NOTHING: {
        name: 'Vaihtoehtoinen',
        icon: <DesktopOutlined />,
    },
    BACKGROUND_PROCESS_AND_LAST_CHECK_WITH_CUSTOMER_ACCOUNTS: {
        name: 'Standardi',
        icon: <AuditOutlined />,
    },
} as const;

export const tolBusinessLines: { [tol: string]: string } = {
    '68202': 'Asuntojen ja asuinkiinteistöjen hallinta',
    '68209': 'Muiden kiinteistöjen vuokraus ja hallinta',
    '66300': 'Omaisuudenhoitotoiminta',
    '90030': 'Taiteellinen luominen',
    '78200': 'Työvoiman vuokraus',
    '46442': 'Puhdistusaineiden tukkukauppa',
    '64300': 'Rahastotoiminta',
    '94991': 'Tutkimusta ja kulttuuria palvelevat järjestöt',
    '75000': 'Eläinlääkintäpalvelut',
    '85200': 'Alemman perusasteen koulutus',
    '94120': 'Ammattialajärjestöjen toiminta',
    '56101': 'Ravintolat',
    '66120': 'Arvopaperien ja raaka-ainesopimusten välittäminen',
    '56302': 'Kahvilat ja kahvibaarit',
};
