import axios from 'axios';
import qs from 'qs';
import { apiBase, APIResponse, Collection, getAuthHeader } from '.';
import {
    Approval,
    Automations,
    Client,
    ClientCOA,
    ClientDimensions,
} from '../interfaces';

export type ClientColumn =
    | 'id'
    | 'name'
    | 'startDateForFetchingInvoices'
    | 'createdAt'
    | 'updatedAt'
    | 'organization';

export interface ClientParameters {
    key?: string;
    businessId?: string;
    integrationKey?: string;
    searchQuery?: string;
    organization?: string;
    team?: string;
    status?: string;
    trainingStatus?: string;
    accountingSystem?: string;
    serviceTemplate?: string;
    allProviders?: boolean;
    limit?: number;
    page?: number;
    orderBy?: ClientColumn;
    order?: 'asc' | 'desc';
    [key: string]: string | number | boolean | undefined;
}

export const getClients = async (
    params: ClientParameters = { page: 1, limit: 10, orderBy: 'name' }
): Promise<Collection<Client>> => {
    params.allProviders = true;

    const result: APIResponse<Client[]> = await axios({
        method: 'GET',
        url: `${apiBase}/clients?${qs.stringify({
            ...params,
            searchQuery: params.searchQuery
                ? 'fuzzy:' + params.searchQuery
                : undefined,
            organization: params.organization
                ? 'search:' + params.organization
                : undefined,
            team: params.team === '-' ? params.team : (params.team ? 'search:' + params.team : undefined),
        })}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return {
        totalCount: result.data.totalCount || 0,
        items: result.data.data || [],
    };
};

export const getClientByID = async (id: number | string): Promise<Client> => {
    const result = await axios({
        method: 'GET',
        url: `${apiBase}/clients/${id}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return result.data.data;
};

export const updateClient = async (
    id: number,
    data: Partial<Client>
): Promise<Client> => {
    const result = await axios({
        method: 'PATCH',
        url: `${apiBase}/clients/${id}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
        data,
    });

    return result.data.data;
};

export const updateClientAutomation = async (
    clientId: number,
    automationId: number,
    data: Partial<Approval>
): Promise<Approval> => {
    const result = await axios({
        method: 'PATCH',
        url: `${apiBase}/clients/${clientId}/automations/${automationId}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
        data,
    });

    return result.data.data;
};

export const createClientAutomation = async (
    clientId: number,
    data: Partial<Approval>
): Promise<Automations> => {
    const result = await axios({
        method: 'POST',
        url: `${apiBase}/clients/${clientId}/automations`,
        headers: {
            Authorization: await getAuthHeader(),
        },
        data,
    });

    return result.data.data;
};

export const deleteClientAutomation = async (
    clientId: number,
    automationId: number
): Promise<void> => {
    const result = await axios({
        method: 'DELETE',
        url: `${apiBase}/clients/${clientId}/automations/${automationId}`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return result.data.data;
};

export const getClientCOA = async (clientId: number): Promise<ClientCOA> => {
    const result = await axios({
        method: 'GET',
        url: `${apiBase}/clients/${clientId}/coa`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return result.data.data;
};

export const getClientDimensions = async (
    clientId: number
): Promise<ClientDimensions> => {
    const result = await axios({
        method: 'GET',
        url: `${apiBase}/clients/${clientId}/dimensions`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return result.data.data;
};

export const createClient = async (data: Partial<Client>): Promise<Client> => {
    const result = await axios({
        method: 'POST',
        url: `${apiBase}/clients`,
        headers: {
            Authorization: await getAuthHeader(),
        },
        data,
    });

    return result.data.data;
};

export const reloadTrainingData = async (clientId: number): Promise<void> => {
    await axios({
        method: 'POST',
        url: `${apiBase}/clients/${clientId}/reloadTrainingData`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });
};

// This function is used to convert ANY system name into a prettier format
export const getSystemLabel = (name: string) => {
    // Split the name by dashes and capitalize all words,
    // Uppercase the word AI
    return name
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replace('Ai', 'AI');
};

export const getAccountingSystems = async (): Promise<
    { name: string; label: string }[]
> => {
    const result = await axios({
        method: 'GET',
        url: `${apiBase}/accountingSystems`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return result.data.data.map((name: string) => ({
        name,
        label: getSystemLabel(name),
    }));
};

export const dataDeletionRequest = async (clientId: number): Promise<void> => {
    const reuslt = await axios({
        method: 'POST',
        url: `${apiBase}/clients/${clientId}/dataDeletionRequest`,
        headers: {
            Authorization: await getAuthHeader(),
        },
    });

    return reuslt.data.data;
};
