import { CheckOutlined } from '@ant-design/icons';
import {
    Affix,
    Alert,
    Button,
    Card,
    Divider,
    message,
    Modal,
    Progress,
    Select,
    Space,
    Tooltip,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '../api';
import { updateClient } from '../api/clients';
import { createMLModel } from '../api/mlModels';
import { getTeamByID, updateTeam } from '../api/teams';
import { Client, Team } from '../interfaces';
import { serviceTemplates } from '../util/constants';
import AddToTeam from './AddToTeam';
import OrganizationSwitcher from './OrganizationSwitcher';

interface ClientMassActionToolbarProps {
    clients?: Client[];
}

const ClientMassActionToolbar = ({ clients }: ClientMassActionToolbarProps) => {
    if (!clients || clients.length === 0) return null;

    const queryClient = useQueryClient();

    const clientUpdater = (client: Client, organizationId: number | null) =>
        updateClient(client.id, { organizationId }).then((client) => {
            queryClient.setQueryData(['client', client.id], client);
            queryClient.invalidateQueries('clients');
        });

    const updateClientStatusMutation = useMutation(
        (active: boolean) =>
            Promise.all(
                clients.map((client) =>
                    updateClient(client.id, {
                        status: active ? 'ACTIVE' : 'INACTIVE',
                    })
                )
            ),
        {
            onSuccess: (clients, active) => {
                clients.forEach((client) => {
                    queryClient.setQueryData(['client', client.id], client);
                });

                queryClient.invalidateQueries('clients');

                message.success(
                    active
                        ? 'Asiakkaat asetettu aktiiviseksi'
                        : 'Asiakkaat asetettu epäaktiiviseksi'
                );
            },
        }
    );

    const addClientsToTeamMutation = useMutation(
        ({ id }: Team) =>
            // First we fetch the latest version of the team, to no inadvertently overwrite unsynced changes
            getTeamByID(id).then((team) => 
                updateTeam(id, {
                    ...team,
                    clients: [...team.clients, ...clients],
                })
            ),
        {
            onSuccess: (team) => {
                queryClient.setQueryData(['team', team.id], team);
                queryClient.invalidateQueries('teams');
                queryClient.invalidateQueries('clients');
                message.success('Asiakkaat lisätty tiimiin');
            },
        }
    );

    const removeClientsFromTeamMutation = useMutation(
        () =>
            Promise.all(
                clients.map((client) => updateClient(client.id, { teams: [] }))
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('teams');
                queryClient.invalidateQueries('clients');
                message.success('Asiakkaat poistettu tiimeistä');
            },
        }
    );

    const updateServiceTemplatesMutation = useMutation(
        (template: string) =>
            Promise.all(
                clients.map((client) =>
                    updateClient(client.id, { serviceTemplate: template })
                )
            ),
        {
            onSuccess: (clients, template) => {
                clients.forEach((client) => {
                    queryClient.setQueryData(['client', client.id], client);
                });

                queryClient.invalidateQueries('clients');

                message.success(`Palvelumallit päivitetty arvoon ${template}`);
            },
        }
    );

    const active: boolean | undefined = clients.every(
        (client) => client.status === 'ACTIVE'
    )
        ? true
        : clients.every((client) => client.status === 'INACTIVE')
        ? false
        : undefined;

    const commonOrganizationId =
        (clients.every(
            (client) => client.organizationId === clients[0]!.organizationId
        ) &&
            clients[0]!.organizationId) ||
        undefined;

    return (
        <Affix>
            <Card
                style={{
                    borderColor: '#e1e1e1',
                    marginBottom: '12px',
                }}
                size="small"
            >
                <Space
                    split={<Divider type="vertical" />}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '12px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '120px',
                        }}
                    >
                        <Text>Massatoiminnot</Text>
                        <Text type="secondary" style={{ marginTop: '4px' }}>
                            {clients.length} asiakasta valittu
                        </Text>
                    </div>
                    <OrganizationSwitcher
                        entities={clients}
                        updater={clientUpdater}
                    />
                    <AddToTeam
                        onSubmit={(team) =>
                            addClientsToTeamMutation.mutate(team)
                        }
                        onRemove={() => removeClientsFromTeamMutation.mutate()}
                        submitting={
                            addClientsToTeamMutation.isLoading ||
                            removeClientsFromTeamMutation.isLoading
                        }
                        organizationId={commonOrganizationId}
                    />
                    <Button
                        disabled={active === undefined}
                        loading={updateClientStatusMutation.isLoading}
                        onClick={() =>
                            updateClientStatusMutation.mutate(!active)
                        }
                    >
                        {active
                            ? 'Aseta epäaktiiviseksi'
                            : 'Aseta aktiiviseksi'}
                    </Button>
                    <ServiceTemplateSwitcher
                        onSubmit={updateServiceTemplatesMutation.mutate}
                        loading={updateServiceTemplatesMutation.isLoading}
                    />
                    <TrainAccountPredictionModel clients={clients} />
                </Space>
            </Card>
        </Affix>
    );
};

export default ClientMassActionToolbar;

const ServiceTemplateSwitcher = ({
    onSubmit,
    loading,
}: {
    onSubmit: (template: string) => void;
    loading: boolean;
}) => {
    const [serviceTemplate, setServiceTemplate] = useState<string | undefined>(
        undefined
    );

    const handleSubmit = () => {
        if (!serviceTemplate) return;

        onSubmit(serviceTemplate);
        setServiceTemplate(undefined);
    };

    return (
        <Space>
            <Select
                disabled={loading}
                allowClear
                style={{ minWidth: 200 }}
                onChange={(value) => setServiceTemplate(value)}
                value={serviceTemplate}
                placeholder="Aseta palvelumalli"
            >
                {Object.keys(serviceTemplates).map((template) => (
                    <Select.Option key={template} value={template}>
                        {serviceTemplates[template]!.name}
                    </Select.Option>
                ))}
            </Select>
            <Tooltip title="Aseta">
                <Button
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={!serviceTemplate}
                    icon={<CheckOutlined />}
                />
            </Tooltip>
        </Space>
    );
};

const TrainAccountPredictionModel = (props: { clients: Client[] }) => {
    const { clients = [] } = props;
    const [requestLog, setRequestLog] = useState<string[]>([]);
    const trainingRequestMutation = useMutation(async () => {
        setRequestLog([]);
        for (const client of clients) {
            await createMLModel(client.id, {
                label: 'ACCOUNT',
                dimensionId: null,
                priority: null,
            })
                .then(() =>
                    setRequestLog((old) => [
                        ...old,
                        `${client.name} (${client.id}) OK`,
                    ])
                )
                .catch((error: APIError) => {
                    setRequestLog((old) => [
                        ...old,
                        `${client.name} (${client.id}) ERROR: ${error.message}: ${error.response?.data.error}`,
                    ]);
                    throw error;
                });
        }
    });

    return (
        <>
            <Button onClick={() => trainingRequestMutation.mutate()}>
                Kouluta tiliöintimalli
            </Button>
            <Modal
                title="Lähetetään koulutuspyyntöjä"
                open={
                    trainingRequestMutation.isLoading || requestLog.length > 0
                }
                closable={!trainingRequestMutation.isLoading}
                onCancel={() => setRequestLog([])}
                footer={
                    <Button
                        onClick={() => setRequestLog([])}
                        disabled={trainingRequestMutation.isLoading}
                        type="primary"
                    >
                        Sulje
                    </Button>
                }
            >
                <Space
                    style={{ textAlign: 'center', width: '100%' }}
                    direction="vertical"
                >
                    <Progress
                        status={
                            trainingRequestMutation.isError
                                ? 'exception'
                                : undefined
                        }
                        type="circle"
                        percent={Math.round(
                            (requestLog.length / clients.length) * 100
                        )}
                    />
                    <Text>
                        {requestLog.length}/{clients.length}
                    </Text>
                    {trainingRequestMutation.isError && (
                        <Alert
                            message="Virheloki (kopioi talteen)"
                            type="error"
                            description={<pre>{requestLog.join('\n')}</pre>}
                        />
                    )}
                </Space>
            </Modal>
        </>
    );
};
